import { Button, Intent, Label, HTMLSelect, Menu, MenuItem, MenuDivider, Icon } from '@blueprintjs/core'
import { useState } from 'react'
import { Row, Col } from 'react-grid-system'
import PackSettings from './pack'
import rfdc from 'rfdc'
import css from './patient.module.css'

const clone = rfdc()
const hourOptions = []
const hourToText = {}
for (let i = 1; i <= 24; i++) {
  let text
  if (i === 24) text = '12AM'
  else if (i === 12) text = '12PM'
  else if (i < 12) text = `${i}AM`
  else text = `${i - 12}PM`
  hourOptions.push(
    <option value={i} key={i}>
      {text}
    </option>
  )
  hourToText[i] = text
}

function MonthlySchedule ({ patient, savePatient }) {
  const { firstName, lastName, phone } = patient
  const [schedule, setSchedule] = useState(patient.packSchedule)
  const [activeDose, setActiveDose] = useState(0)

  console.log(schedule)

  const save = () => savePatient({ ...patient, packSchedule: schedule })

  const addDose = () => {
    const { packs } = schedule
    packs.push(clone(packs[packs.length - 1]))
    setSchedule({ ...schedule, packs })
  }

  const handleDoseClick = (i, e) => {
    if (['A', 'DIV', 'SPAN'].includes(e.target.tagName)) {
      setActiveDose(i)
    } else {
      if (activeDose > 0 && i <= activeDose) setActiveDose(activeDose - 1)
      schedule.packs.splice(i, 1)
      setSchedule({ ...schedule })
    }
  }

  const onTimeChange = (value, key) => {
    const packs = schedule.packs
    packs[activeDose][key] = parseInt(value)
    setSchedule({ ...schedule, packs })
  }

  const onScheduleChange = (newSchedule) => {
    schedule.packs[activeDose].schedule = newSchedule
    setSchedule({ ...schedule })
  }

  if (!schedule) return null

  const activeSchedule = schedule.packs[activeDose]

  return (
    <>
      <Row style={{ marginLeft: 0 }}>
        <Col md={3} style={{ backgroundColor: '#f5f8fa' }}>
          <h2>
            {firstName} {lastName}
          </h2>
          <div>
            <strong>Phone:</strong> {phone}
          </div>
          <Menu style={{ minWidth: 'auto', marginTop: 10 }}>
            {schedule.packs.map((s, i) => (
              <MenuItem
                key={i}
                active={i === activeDose}
                onClick={(e) => handleDoseClick(i, e)}
                text={`Dose ${i + 1} (${hourToText[s.start]} - ${hourToText[s.finish]})`}
                labelElement={schedule.packs.length > 1 && <Icon icon="ban-circle" />}
              />
            ))}
            <MenuDivider />
            <MenuItem icon="add" text="Add dose" onClick={addDose} />
          </Menu>
          <Button intent={Intent.SUCCESS} icon="endorsed" style={{ width: '100%', marginTop: 10 }} onClick={save}>
            Save
          </Button>
        </Col>
        <Col md={6}>
          <h2 className={css.monthlyTimeRange}>
            <span>Dose {activeDose + 1}&nbsp;</span>
            <HTMLSelect value={activeSchedule.start} onChange={(e) => onTimeChange(e.target.value, 'start')}>
              {hourOptions}
            </HTMLSelect>
            <Label className="bp3-inline">-</Label>
            <HTMLSelect value={activeSchedule.finish} onChange={(e) => onTimeChange(e.target.value, 'finish')}>
              {hourOptions}
            </HTMLSelect>
          </h2>
          <PackSettings type={patient.packType} schedule={activeSchedule.schedule} setSchedule={onScheduleChange} />
        </Col>
      </Row>
    </>
  )
}

export default MonthlySchedule
