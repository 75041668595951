import { patientsApi } from '../../api'

async function exportPacks (patient) {
  const data = {
    patientId: patient._id,
    patientClinic: patient.patientClinic,
    timezone: patient.timezone
  }

  const patientClinicMap = new Map([
    ['0', 'none'],
    ['1', 'RAAC (British Columbia)'],
    ['2', 'RSRC (New Brunswick)'],
    ['3', 'CAMH (Ontario)'],
    ['4', 'CRCHUM (Quebec)'],
    ['5', '(Prairies)'],
    ['6', 'Clinic Ref 1'],
    ['7', 'Clinic Ref 2'],
    ['8', 'Clinic Ref 3']
  ])

  const patientClinicName = patientClinicMap.get(patient.patientClinic)
  const date = new Date()

  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  const filePath = patientClinicName + '_ASCME_SMACdataimport_' + day + '-' + month + '-' + year + '.csv'

  let result = null
  try {
    result = await patientsApi.post('rx/exportCSV', data).then((response) => {
      if (response.status === 200) {
        const blob = new Blob([response.data], { type: 'text/csv' })

        const FileSaver = require('file-saver')
        FileSaver.saveAs(blob, filePath)
      } else {
        alert('Failed to export csv file for sticker id mapping!')
      }
    })
  } catch (error) {
    console.log('[CSVEXPORT] failed with error: ' + JSON.stringify(error))
  }

  return result
}

export default exportPacks
