import React from 'react'
import { Navbar } from '@blueprintjs/core'
import { Container } from 'react-grid-system'

function Footer () {
  return (
    <Navbar style={{ height: 'auto' }}>
      <Container style={{ textAlign: 'center' }}>
        <div style={{ color: '#5c7080', paddingTop: 20 }}>Bringing caregivers peace of mind by improving patients' quality of life</div>
        <Navbar.Group style={{ display: 'inline-flex', float: 'none' }}>
          <span>CuePath Innovation Inc.</span>
          <Navbar.Divider />
          <a href='https://cuepath.com/privacy-policy/' target='_blank' rel='noreferrer'>Privacy Policy</a>
          <Navbar.Divider />
          <a href='https://cuepath.com/site-map/' target='_blank' rel='noreferrer'>Site Map</a>
          <Navbar.Divider />
          <a href='https://cuepath.com/terms-conditions/' target='_blank' rel='noreferrer'>Terms & Conditions</a>
        </Navbar.Group>
      </Container>
    </Navbar>
  )
}

export default Footer
