import React from 'react'
import { Button, Card, Elevation, FormGroup, InputGroup } from '@blueprintjs/core'
import logo from '../../images/cuepath-logo-big.png'
import css from './login.module.css'

function Login ({ rxId, clientId, setClientId, setRxId, fetchData }) {
  return (
    <Card interactive elevation={Elevation.THREE} className={css.card}>
      <img src={logo} alt='logo' className={css.logo} />
      <h2 style={{ textAlign: 'center' }}>Welcome to the Pharmacy Activation App</h2>
      <FormGroup
        label='Pharmacy ID'
        labelInfo='(required)'
      >
        <InputGroup placeholder='Pharmacy ID...' value={rxId} onChange={(e) => setRxId(e.target.value)} name='rxId' />
      </FormGroup>
      <FormGroup
        label='Client ID'
        labelInfo='(required)'
      >
        <InputGroup placeholder='Client ID...' value={clientId} onChange={(e) => setClientId(e.target.value)} name='clientId' />
      </FormGroup>
      <FormGroup>
        <Button icon='log-in' onClick={fetchData} text='Log In' />
      </FormGroup>
    </Card>
  )
}

export default Login
