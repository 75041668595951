import React, { useEffect, useState } from 'react'
import rfdc from 'rfdc'
import moment from 'moment-timezone'
import { useHistory } from 'react-router-dom'
import { Button, Switch, Intent, Menu, MenuItem, MenuDivider, Icon, Tooltip, Label, Classes } from '@blueprintjs/core'
import { Row, Col } from 'react-grid-system'
import PackSettings from '../pack'
import Timings from './timings'
import exportPacks from '../../export/exportPacks'

const clone = rfdc()

function Schedule ({ patient, savePatient, saveAssignParticipant }) {
  const { firstName, lastName, phone } = patient

  const [packSchedule, setSchedule] = useState(patient.packSchedule)
  const [activeWeek, setActiveWeek] = useState(0)
  const [stickerId, setStickerId] = useState('')
  const [recordId, setRecordId] = useState('')
  const [eventInfo, setEventInfo] = useState('w1d1_arm_2')

  const history = useHistory()

  useEffect(() => {
    if (patient.packSchedule.type === 'dynamic') history.push('/dynamic')
  }, [patient])

  const handleRecordIdChange = (event) => {
    setRecordId(event.target.value)
  }

  const handleStickerIdChange = (event) => {
    setStickerId(event.target.value)
  }

  const handleEventInfoChange = (event) => {
    setEventInfo(event.target.value)
  }

  const handleDynamicChange = (event) => {
    const { checked } = event.target
    setActiveWeek(0)
    setSchedule({ ...packSchedule, type: checked ? 'dynamic' : 'default' })
    history.push(checked ? '/dynamic' : '/')
  }

  const addWeek = () => {
    const { weeks } = packSchedule
    weeks.push(clone(weeks[weeks.length - 1]))
    setSchedule({ ...packSchedule, weeks })
  }

  const handleWeekClick = (i, e) => {
    if (['A', 'DIV', 'SPAN'].includes(e.target.tagName)) {
      setActiveWeek(i)
    } else {
      if (i <= activeWeek) setActiveWeek(activeWeek - 1)
      packSchedule.weeks.splice(i, 1)
      setSchedule({ ...packSchedule })
    }
  }

  const saveActiveSchedule = (activeSchedule) => {
    if (packSchedule.type === 'dynamic') {
      packSchedule.weeks[activeWeek] = activeSchedule
      if (activeSchedule.current) packSchedule.currentWeek = activeSchedule
    } else {
      if (activeWeek === 'current') packSchedule.currentWeek = activeSchedule
      else packSchedule.weeks[0] = activeSchedule
    }
    setSchedule({ ...packSchedule })
  }

  const save = () => savePatient({ ...patient, packSchedule: packSchedule })
  const assignPack = () => saveAssignParticipant({ ...patient, stickerId: stickerId, event: eventInfo, recordId: recordId, patientId: patient._id })

  const activeSchedule = activeWeek === 'current' ? packSchedule.currentWeek : packSchedule.weeks[activeWeek]

  const currentPackDates = `${moment(packSchedule.currentWeekDetails.startDate).format('DD MMM')} - ${moment(
    packSchedule.currentWeekDetails.endDate
  ).format('DD MMM')}`
  const currentWeekIndex = packSchedule.currentWeekDetails.index || 0

  let weekHeader
  if (activeWeek === 'current') {
    weekHeader = (
      <>
        Current week schedule <br /> {currentPackDates}
      </>
    )
  } else if (packSchedule.type === 'dynamic') {
    weekHeader = (
      <>
        Week {activeWeek + 1 + currentWeekIndex} <br />{' '}
        {activeWeek === packSchedule.weeks.length - 1
          ? 'and following weeks'
          : packSchedule.currentWeek
            ? `${moment(packSchedule.currentWeekDetails.startDate)
              .add(activeWeek + 1, 'w')
              .format('DD MMM')} - ${moment(packSchedule.currentWeekDetails.endDate)
              .add(activeWeek + 1, 'w')
              .format('DD MMM')}`
            : null}
      </>
    )
  } else weekHeader = packSchedule.currentWeek ? 'Following weeks schedule' : 'All weeks schedule'

  const currentWeekButton = packSchedule.currentWeek
    ? (
        activeWeek === 'current'
          ? (
      <Button icon="step-backward" style={{ width: '100%', marginTop: 10 }} onClick={() => setActiveWeek(0)}>
        Back to all weeks
      </Button>
            )
          : (
      <Button
        intent={Intent.WARNING}
        icon="time"
        style={{ width: '100%', marginTop: 10 }}
        onClick={() => setActiveWeek('current')}
      >
        Edit current week
      </Button>
            )
      )
    : null

  return (
    <>
      <Row style={{ marginLeft: 0 }}>
        <Col md={3} style={{ backgroundColor: '#f5f8fa' }}>
          <h2>
            {firstName} {lastName}
          </h2>
          <div>
            <strong>Phone:</strong> {phone}
          </div>
          {packSchedule.currentWeek && (
            <div>
              <strong>Current week:</strong> {currentPackDates}
            </div>
          )}
          {packSchedule.currentWeek && (
            <div>
              <strong>Current week number:</strong> {currentWeekIndex}
            </div>
          )}
          <Tooltip
            content={
              <>
                Use this only when the schedule
                <br />
                varies from week to week
              </>
            }
          >
            <Switch
              id="dynamicSchedule"
              label="Dynamic schedule"
              checked={packSchedule.type === 'dynamic'}
              onChange={handleDynamicChange}
              style={{ paddingTop: 15 }}
            />

          </Tooltip>
          {currentWeekButton}
          {packSchedule.type === 'dynamic' && (
            <>
              <Menu style={{ minWidth: 'auto', marginTop: 10 }}>
                {packSchedule.weeks.map((week, i) => (
                  <MenuItem
                    key={i}
                    active={i === activeWeek}
                    onClick={(e) => handleWeekClick(i, e)}
                    text={`Week ${i + 1 + currentWeekIndex}`}
                    labelElement={i > 0 && <Icon icon="ban-circle" />}
                  />
                ))}
                <MenuDivider />
                <MenuItem icon="add" text="Add week" onClick={addWeek} />
              </Menu>
            </>
          )}

          { patient.patientType !== 'lab' &&
            <Button intent={Intent.SUCCESS} icon="endorsed" style={{ width: '100%', marginTop: 10 }} onClick={save}>
              Save
            </Button>
          }

          {patient.patientType === 'lab' &&
            <div style={{ marginTop: 80 }} >
              <h2>
                Assign Participant
              </h2>

              <Label>
                  Sticker Id
                  <input
                      type="text"
                      id="stickerInputId"
                      name="stickerInputId"
                      onChange={handleStickerIdChange}
                      style={{ width: '70%', marginTop: 10 }}
                      className={Classes.INPUT} style={{ width: '70%', marginTop: 10 }} placeholder="X0000" />
              </Label>

              <Label>
                  Record Id
                  <input
                      type="text"
                      id="recordIdInputId"
                      name="recordIdInputId"
                      onChange={handleRecordIdChange}
                      style={{ width: '70%', marginTop: 10 }}
                      className={Classes.INPUT} style={{ width: '70%', marginTop: 10 }} placeholder="XXX123" />
              </Label>

              <Label>
                  Event Name

                  <select
                    className={Classes.SELECT}
                    onChange={handleEventInfoChange}
                    style={{ height: '120%', width: '70%', marginTop: 10, 'font-size': 15 }} >
                      <option value="w1d1_arm_2" selected="selected">Week 1: Day 1</option>
                      <option value="w1d3_arm_2">Week 1: Day 3</option>
                      <option value="w1d5_arm_2">Week 1: Day 5</option>
                      <option value="w2_arm_2">Week 2</option>
                      <option value="w3_arm_2">Week 3</option>
                      <option value="w4_arm_2">Week 4</option>
                      <option value="w5_arm_2">Week 5</option>
                      <option value="w6_arm_2">Week 6</option>
                      <option value="w7_arm_2">Week 7</option>
                      <option value="w8_arm_2">Week 8</option>
                      <option value="w9_arm_2">Week 9</option>
                      <option value="w10_arm_2">Week 10</option>
                      <option value="w11_arm_2">Week 11</option>
                      <option value="w12_arm_2">Week 12</option>
                      <option value="w13_arm_2">Week 13</option>
                      <option value="w14_arm_2">Week 14</option>
                      <option value="w15_arm_2">Week 15</option>
                  </select>
              </Label>
              <Button intent={Intent.PRIMARY} icon="endorsed" style={{ width: '70%', marginTop: 10 }} onClick={assignPack}>
                Assign
              </Button>

              <Button intent={Intent.SUCCESS} style={{ width: '70%', marginTop: 10 }} onClick={() => exportPacks(patient)}>
                Export All Packs
              </Button>

            </div>
          }
        </Col>
        <Col md={3}>
          <h2>{weekHeader}</h2>
          <Timings schedule={activeSchedule} setSchedule={saveActiveSchedule} />
        </Col>
        <Col md={6} style={{ minHeight: 'calc(100vh - 138px)' }}>
          <PackSettings type={patient.packType} schedule={activeSchedule} setSchedule={saveActiveSchedule} />
        </Col>
      </Row>
    </>
  )
}

export default Schedule
