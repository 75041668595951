import React from 'react'
import moment from 'moment-timezone'
import { Button, Intent } from '@blueprintjs/core'
import config from '../../../config'
import css from './styles.module.css'
import AddPackColumnHeader from './add-pack-column-header'
import AddPackColumnFooter from './add-pack-column-footer'

function getRowDates (startSunday = false) {
  const rowDates = []
  for (let i = 1; i <= 7; i++) {
    rowDates.push(
      moment()
        .isoWeekday(startSunday ? i - 1 : i)
        .format('ddd')
    )
  }
  return rowDates
}

function RowButton ({ schedule, rowKey, toggleRow }) {
  let rowEnabled = schedule.column0[rowKey]
  for (let i = 1; i < 4 && rowEnabled !== null; i++) {
    if (schedule[`column${i}`][rowKey] !== rowEnabled) rowEnabled = null
  }
  const intent = rowEnabled ? Intent.SUCCESS : rowEnabled === false ? Intent.DANGER : Intent.NONE
  const text = rowEnabled === false ? 'DISABLED' : 'ENABLED'
  return <Button className={css.rowButton} text={text} intent={intent} onClick={() => toggleRow(rowKey, rowEnabled)} />
}

function RowDescriptions ({ type, schedule, toggleRow }) {
  const startSunday = type === 'thrifty28days' || config.packStartOnSunday
  const rows = getRowDates(startSunday).map((rowDate, i) => {
    return (
      <div className={css.rowDescription} key={i}>
        <div className={css.weekDay}>{rowDate.toUpperCase()}</div>
        {type === 'thrifty28days' && <RowButton rowKey={`row${i}`} schedule={schedule} toggleRow={toggleRow} />}
      </div>
    )
  })

  return (
    <div className={css.rowDescriptions}>
      <AddPackColumnHeader type={type} />
      {rows}
      <AddPackColumnFooter />
    </div>
  )
}

export default RowDescriptions
