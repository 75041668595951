import React from 'react'
import { Classes, Navbar, Button, ControlGroup, InputGroup } from '@blueprintjs/core'
import { Container } from 'react-grid-system'
import classNames from 'classnames'

import css from './top-bar.module.css'
import logo from '../../../images/cuepath_icon_full_color.png'

function TopBar ({ patient, rxId, clientId, setClientId, setRxId, fetchData }) {
  return (
    <Navbar className={classNames(css.topBar, Classes.ELEVATION_4)}>
      <Container style={{ maxWidth: 1200 }}>
        <Navbar.Group>
          <img src={logo} alt='logo' className={css.logo} />
          <Navbar.Heading className={css.topBarHeading}>CuePath Pharmacy Activation App</Navbar.Heading>
          {patient &&
            <ControlGroup>
              <InputGroup placeholder='Rx ID...' value={rxId} onChange={(e) => setRxId(e.target.value)} name='rxId' />
              <InputGroup placeholder='Client ID...' value={clientId} onChange={(e) => setClientId(e.target.value)} name='clientId' />
              <Button icon='arrow-right' onClick={fetchData} />
            </ControlGroup>}
        </Navbar.Group>
      </Container>
    </Navbar>
  )
}

export default TopBar
