import React from 'react'
import rfdc from 'rfdc'
import { FormGroup, Label, HTMLSelect, Switch } from '@blueprintjs/core'
import { defaultWeekSchedule } from '../../../config/defaultSchedules'
import css from '../patient.module.css'

const clone = rfdc()

function Timings ({ schedule, setSchedule }) {
  function handleInputChange (event) {
    const { id, value } = event.target
    const [column, time] = id.split('.')
    const newSchedule = clone(schedule)
    newSchedule[column][time] = parseInt(value)

    const valid =
      newSchedule.column0.start < newSchedule.column0.finish &&
      newSchedule.column1.start < newSchedule.column1.finish &&
      newSchedule.column2.start < newSchedule.column2.finish &&
      newSchedule.column3.start < newSchedule.column3.finish

    if (!valid) return window.alert('Start time should be earlier than end time')

    setSchedule(newSchedule)
  }

  function handleDefaultChange (event) {
    const { checked: useDefault } = event.target
    if (useDefault) setSchedule(clone(defaultWeekSchedule))
    else setSchedule({ ...schedule, useDefault })
  }

  const input = {
    'column0.start': schedule.column0.start,
    'column0.finish': schedule.column0.finish,
    'column1.start': schedule.column1.start,
    'column1.finish': schedule.column1.finish,
    'column2.start': schedule.column2.start,
    'column2.finish': schedule.column2.finish,
    'column3.start': schedule.column3.start,
    'column3.finish': schedule.column3.finish
  }

  const hourOptions = []
  for (let i = 1; i <= 24; i++) {
    let text
    if (i === 24) text = '12AM'
    else if (i === 12) text = '12PM'
    else if (i < 12) text = `${i}AM`
    else text = `${i - 12}PM`
    hourOptions.push(
      <option value={i} key={i}>
        {text}
      </option>
    )
  }

  const TimingInput = ({ id }) => (
    <HTMLSelect
      disabled={schedule.useDefault}
      id={id}
      className={css.timingsInput}
      onChange={handleInputChange}
      value={input[id]}
    >
      {hourOptions}
    </HTMLSelect>
  )

  return (
    <FormGroup>
      <Switch
        id="useDefault"
        checked={!!schedule.useDefault}
        onChange={handleDefaultChange}
        label="Use default medication schedule"
      />
      <Label>Breakfast</Label>
      <div className={css.timingsRange}>
        <TimingInput id="column0.start" />
        <Label className="bp3-inline">-</Label>
        <TimingInput id="column0.finish" />
      </div>

      <Label>Lunch</Label>
      <div className={css.timingsRange}>
        <TimingInput id="column1.start" />
        <Label className="bp3-inline">-</Label>
        <TimingInput id="column1.finish" />
      </div>

      <Label>Supper</Label>
      <div className={css.timingsRange}>
        <TimingInput id="column2.start" />
        <Label className="bp3-inline">-</Label>
        <TimingInput id="column2.finish" />
      </div>

      <Label>Bedtime</Label>
      <div className={css.timingsRange}>
        <TimingInput id="column3.start" />
        <Label className="bp3-inline">-</Label>
        <TimingInput id="column3.finish" />
      </div>
    </FormGroup>
  )
}

export default Timings
