const defaultWeekSchedule = {
  useDefault: true,
  column0: {
    start: 7,
    finish: 9,
    row0: true,
    row1: true,
    row2: true,
    row3: true,
    row4: true,
    row5: true,
    row6: true
  },
  column1: {
    start: 11,
    finish: 13,
    row0: true,
    row1: true,
    row2: true,
    row3: true,
    row4: true,
    row5: true,
    row6: true
  },
  column2: {
    start: 16,
    finish: 18,
    row0: true,
    row1: true,
    row2: true,
    row3: true,
    row4: true,
    row5: true,
    row6: true
  },
  column3: {
    start: 20,
    finish: 22,
    row0: true,
    row1: true,
    row2: true,
    row3: true,
    row4: true,
    row5: true,
    row6: true
  }
}

const default28DaysSchedule = {
  column0: {
    row0: true,
    row1: true,
    row2: true,
    row3: true,
    row4: true,
    row5: true,
    row6: true
  },
  column1: {
    row0: true,
    row1: true,
    row2: true,
    row3: true,
    row4: true,
    row5: true,
    row6: true
  },
  column2: {
    row0: true,
    row1: true,
    row2: true,
    row3: true,
    row4: true,
    row5: true,
    row6: true
  },
  column3: {
    row0: true,
    row1: true,
    row2: true,
    row3: true,
    row4: true,
    row5: true,
    row6: true
  }
}

const packScheduleJones7days = {
  type: 'default',
  weeks: [defaultWeekSchedule],
  currentWeekDetails: {
    index: 0
  }
}

const packScheduleThrifty28days = {
  packs: [
    { start: 7, finish: 12, schedule: default28DaysSchedule },
    { start: 12, finish: 16, schedule: default28DaysSchedule },
    { start: 16, finish: 20, schedule: default28DaysSchedule }
  ]
}

export { defaultWeekSchedule, default28DaysSchedule, packScheduleJones7days, packScheduleThrifty28days }
