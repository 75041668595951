import WeeklySchedule from './weeklySchedule'
import MonthlySchedule from './monthlySchedule'

export default ({ patient, savePatient, saveAssignParticipant }) =>
  patient.packType === 'thrifty28days'
    ? (
    <MonthlySchedule patient={patient} savePatient={savePatient} saveAssignParticipant={saveAssignParticipant}/>
      )
    : (
    <WeeklySchedule patient={patient} savePatient={savePatient} saveAssignParticipant={saveAssignParticipant}/>
      )
