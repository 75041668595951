import React from 'react'
import css from './styles.module.css'

function AddPackColumnFooter ({ isPackColumn, handleShiftWeekdays }) {
  if (isPackColumn) return <div className={css.columnFooter} />

  return (
    <div className={css.columnFooter} />
  )
}

export default AddPackColumnFooter
