import axios from 'axios'
import config from '../config'

const createApi = ({ baseURL }) => {
  return axios.create({
    baseURL,
    timeout: 120000,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

const patientsApi = createApi({ baseURL: config.urls.patients })
const packsApi = createApi({ baseURL: config.urls.packs })

export {
  patientsApi,
  packsApi
}
