import React, { useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import AppContext from './appContext'
import Patient from './patient/patient'
import config from '../config'

function App () {
  useEffect(() => {
    const script = document.createElement('script')
    script.text = config.userGuidingScript
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <AppContext.Provider>
      <Router>
        <Patient />
      </Router>
    </AppContext.Provider>
  )
}

export default App
