import React from 'react'
import FixedRatioResponsiveContainer from '../../layout/fixed-ratio-responsive-container/fixed-ratio-responsive-container'
import AddPackColumn from './add-pack-column'
import RowDescriptions from './row-descriptions'
import css from '../patient.module.css'

function Pack ({ type, schedule, setSchedule }) {
  function toggleCavity (columnKey, rowKey) {
    const updatedSchedule = { ...schedule }
    updatedSchedule[columnKey][rowKey] = !updatedSchedule[columnKey][rowKey]
    setSchedule(updatedSchedule)
  }

  function toggleColumn (columnKey, columnState) {
    const updatedSchedule = { ...schedule }
    Object.keys(updatedSchedule[columnKey]).forEach((rowKey) => {
      if (rowKey.includes('row')) updatedSchedule[columnKey][rowKey] = !columnState
    })
    setSchedule(updatedSchedule)
  }

  function toggleRow (rowKey, rowState) {
    const updatedSchedule = { ...schedule }
    Object.keys(updatedSchedule).forEach((columnKey) => {
      updatedSchedule[columnKey][rowKey] = !rowState
    })
    setSchedule(updatedSchedule)
  }

  // function handleShiftWeekdays (x) {
  //   let newOffset = weekdaysOffset + x
  //   if (newOffset < 0) newOffset = 6
  //   if (newOffset > 6) newOffset = 0
  //   setWeekdaysOffset(newOffset)
  // }

  const columns = Object.keys(schedule)
    .filter((key) => key.indexOf('column') !== -1)
    .sort()
    .map((key) => (
      <AddPackColumn
        column={schedule[key]}
        key={key}
        columnKey={key}
        type={type}
        toggleCavity={toggleCavity}
        toggleColumn={toggleColumn}
      />
    ))

  return (
    <FixedRatioResponsiveContainer width="100%" minWidth="445px" heightOverWidthRatio={1}>
      <div className={css.packContainer}>
        <div className={css.packBody}>
          <RowDescriptions type={type} schedule={schedule} toggleRow={toggleRow} />
          {columns}
        </div>
      </div>
    </FixedRatioResponsiveContainer>
  )
}

export default Pack
