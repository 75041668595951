import productionUs from './production-us'
import productionCanada from './production-canada'
import development from './development'

const getConfig = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'production-us': return productionUs
    case 'production-canada': return productionCanada
    default: return development
  }
}

export default getConfig()
