import { Toaster, Intent } from '@blueprintjs/core'

const toaster = Toaster.create({ position: 'top-right', canEscapeKeyClear: false })

const serverErrorToast = (err, skipCode = false) => {
  let message = !skipCode && err.response.status ? `${err.response.status} server error occured: ` : ''

  if (err.response) {
    if (err.response.data) {
      if (err.response.data.error && err.response.data.error.message) message += err.response.data.error.message
      else if (err.response.data.message) message += err.response.data.message
      else message += err.toString()
    }
  } else {
    message = err.toString()
  }

  toaster.show({
    intent: Intent.DANGER,
    icon: 'error',
    message,
    timeout: 2000
  })

  console.error(err)
}

const successToast = (message) => {
  toaster.show({
    intent: Intent.SUCCESS,
    message,
    timeout: 2000
  })
}

const errorToast = (message) => {
  toaster.show({
    intent: Intent.DANGER,
    icon: 'error',
    message,
    timeout: 2000
  })
}

export { toaster as default, serverErrorToast, successToast, errorToast }
